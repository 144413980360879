@font-face {
  font-family: "agrandirRegular";
  src: url('./assets/fuentes/AgrandirRegular400.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "agrandirBold";
  src: url('./assets/fuentes/AgrandirTextBold700.otf');
  font-weight: bold;
  font-style: normal;
}


body {
  margin: 0 !important;
  font-family: 'agrandirRegular' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
strong,b{
  font-family: 'agrandirBold' !important;
}


code {
  font-family: 'agrandirRegular' !important;
}

