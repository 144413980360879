
  .bg-image{
    background-image: url('../assets/imagenes/background.png');
    background-size: cover;
    background-repeat: no-repeat;
    
  }

  .bg-image-cursos{
    background-image: url('../assets/imagenes/img5.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .bg-image-cursos2{
    background-image: url('../assets/imagenes/img4.png');
    background-size: cover;
    background-repeat: no-repeat;
  }

  .bg-image-qa{
    background-image: url('../assets/imagenes/img9.png');
    background-size: cover;
    background-repeat: no-repeat;
  }



.bg-image-card {
  background-image: url('../assets/imagenes/card3.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.card .card-title{
  color: white;
  
}

.card .card-subtitle{
  color: yellow;
}



/*  animacion slide primera cards  */
.cards-container {
  display: flex;
  overflow-x: scroll;
}

.cards-wrapper {
  display: flex;
  gap: 20px; /* Espacio entre las cards */
}

.card {
  flex: 0 0 18rem;
}

::-webkit-scrollbar {
   /* Oculta la barra de desplazamiento horizontal */
}


/*Imagen de casos*/
.bg-image-casos{
  background-image: url('../assets/imagenes/card1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.img-prof{
  max-width: 70%;
  height: 100%;
}

/* styles.css */
.custom-carousel span {
  color: black; /* Establecemos el color del texto en negro */
}