.difuminado {
    display: inline-block;
    background-color: rgba(37, 116, 185, 0.3);
    border-radius: 40px;
    width: 90%; /* Usa un ancho relativo para que se adapte al tamaño de pantalla */
    max-width: 500px; /* Establece un ancho máximo para que no crezca demasiado */
    margin: 0 auto; /* Centra el contenedor horizontalmente */
    overflow: hidden; /* Recorta cualquier contenido que sobresalga */
    padding: 1.5em; /* Añade espacio interno alrededor del contenedor */
    box-sizing: border-box; /* Asegura que el padding no afecte el tamaño del contenedor */
}

.difuminado-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* Alinea el texto a la izquierda */
    width: 100%;
    background-image: radial-gradient(circle, rgba(0, 123, 255, 0.6), transparent);
    border-radius: inherit;
    padding: 1.5em;
    text-align: left;
    word-wrap: break-word;
    overflow-wrap: break-word;
    box-sizing: border-box;
}

input {
    background-color: #f0f0f0; /* Color inicial */
    color: black;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
    text-align: center;
    outline: none;
    transition: all 0.3s ease;
  }

  input:focus {
    background-color: #0068e1; /* Color al enfocar */
    color: white;
  }

  input:not(:focus) {
    background-color: #ffffff; /* Color al salir */
    color: black;
  }