.video-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
  }
  
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
  }
  
  .content {
    position: relative;
    z-index: 1;
    color:white; /* Ajusta el color del texto según tu diseño */
    
    
  }
  
  