.logo{
    width: 100%;
}

  

.logos{
    color:white;
    width: 2em;
    font-size: 35px;
}