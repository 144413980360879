.titulos-inicio{
    word-wrap: break-word;
    white-space: normal;
    
}

.hr{
    color:white;
    border:solid;

}

