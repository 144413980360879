
/*Background de slider de inicio*/

.contenedor-1{
    
    background-size: cover;
    
}


/*bord de inicio*/
.bord-1{
    background: linear-gradient(180deg, rgba(141,143,161,1) 0%, rgba(183,198,205,0.4430147058823529) 67%);
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    transform: scale(0.9);
    
}



/*Queines slider*/
.slider-fechas{
    border-radius: 40px;
}


/*tabs*/
.tab-vertical .nav.nav-tabs {
    float: left;
    display: block;
    margin-right: 0px;
    border-bottom: 0;
}

.tab-vertical .nav.nav-tabs .nav-item {
    margin-bottom: 6px;
}

.tab-vertical .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    background: #fff;
    padding: 17px 49px;
    color: #71748d;
    background-color: #dddde8;
    -webkit-border-radius: 4px 0px 0px 4px;
    -moz-border-radius: 4px 0px 0px 4px;
    border-radius: 4px 0px 0px 4px;
}

.tab-vertical .nav-tabs .nav-link.active {
    color: #5969ff;
    background-color: #fff !important;
    border-color: transparent !important;
}

.tab-vertical .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 4px !important;
    border-top-right-radius: 0px !important;
}

.tab-vertical .tab-content {
    overflow: auto;
    -webkit-border-radius: 0px 4px 4px 4px;
    -moz-border-radius: 0px 4px 4px 4px;
    border-radius: 0px 4px 4px 4px;
    background: #fff;
    padding: 30px;
}


/* Modificaion del slick central en el primer slider */


  
  .slick-inicio-slick-center {
    
    transform: translateX(50%);
    transform: scale(0.8);
    


  }

  @media screen and ( min-width: 600px){
    .slick-inicio-slick-center {
        transform: translateX(-50%);
        transform: scale(1.4);
    
      } 
  }

  .slick-prev,
.slick-next {
  transform: scale(1.5) !important;
  /* You can adjust the scale factor to make them larger or smaller as needed */
}

